<template>
  <div class="lists-menu" v-on:click.prevent.stop="">
    <v-list>
      <v-subheader><h3>LE MIE LISTE</h3></v-subheader>
      <!-- v-bind:to="'/profile/lists/' + list.id" -->

      <v-list-item
        v-if="hasFavorites"
        @click.stop="loadList('preferred', $event)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <i18n
              :path="hasFavorites ? 'list.preferred' : 'list.bestselling'"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(list, index) in listsData"
        :key="list.id"
        @click.stop="loadList(list.id, $event)"
        :class="{ zebra: index % 2 == 0 }"
        v-ripple="false"
      >
        <template v-if="!list.isEdit">
          <v-list-item-content>
            <v-list-item-title
              ><span class="mr-2">{{ list.name }}</span>
              <span class="text-caption list-size"
                >({{ $tc("list.listSize", list.size) }})</span
              ></v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action class="d-flex flex-row">
            <v-tooltip left v-if="!list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  x-small
                  v-on:click.prevent.stop="setDefault(list.id)"
                >
                  <v-icon>$star</v-icon>
                </v-btn>
              </template>
              <span> Imposta come lista preferita</span>
            </v-tooltip>
            <v-tooltip left v-if="list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  x-small
                  v-on:click.prevent.stop=""
                >
                  <v-icon>$starfull</v-icon>
                </v-btn>
              </template>
              <span> Questa lista è la tua preferita</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  x-small
                  v-on:click.prevent.stop="setEdit(list)"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
              </template>
              <span>Rinomina lista</span>
            </v-tooltip>

            <v-tooltip left v-if="!list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  x-small
                  v-on:click.stop.prevent="removeList(list.id, list.name)"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </template>
              <span>Elimina lista</span>
            </v-tooltip>
          </v-list-item-action>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-text-field
              flat
              dense
              hide-details="auto"
              v-model="list.tempName"
              v-on:click.prevent.stop="() => {}"
              @keyup.enter.stop="() => {}"
            ></v-text-field>
          </v-list-item-content>
          <v-list-item-action class="d-flex flex-row">
            <v-btn
              class="third-icon-button primary--text"
              x-small
              icon
              v-on:click.prevent.stop="confirmEdit(list)"
            >
              <v-icon>$check</v-icon>
            </v-btn>
            <v-btn
              class="third-icon-button primary--text"
              x-small
              icon
              v-on:click.prevent.stop="undoEdit(list)"
            >
              <v-icon>$close</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>

    <v-row class="d-flex flex-column create-list-wrapper" no-gutters>
      <div class="text-h6 primary--text mb-3 font-weight-bold ml-2">
        CREA UNA NUOVA LISTA
      </div>

      <div class="create-input d-flex align-center">
        <v-text-field
          label="Inserisci il nome"
          hide-details="auto"
          v-model="listName"
        ></v-text-field>
        <v-btn
          medium
          depressed
          v-ripple="false"
          class="secondary"
          v-on:click.prevent.stop="create()"
          :disabled="createDisabled"
          >CREA</v-btn
        >
      </div>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.list-size {
  vertical-align: text-bottom;
}
</style>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import list from "~/mixins/list";

export default {
  name: "ListsMenu",
  mixins: [list],
  // props: ["lists"],
  props: {
    listsData: { type: Array, required: true }
  },
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    async create() {
      await this.createList();
      this.$emit("created");
    },
    loadList(param, e) {
      // used to prevent change route if user is editing list name and presses Enter
      // (workaround: prevent and stop on keyup event don't seem to work)
      if (e.key === "Enter" || e.keyCode === 13) return;
      if (this.$route.params?.listId !== param) {
        this.$router.push({ name: "List", params: { listId: param } });
      }
      this.$nextTick(() => {
        this.$emit("loadlist");
      });
    },
    setDefault(listId) {
      this.$emit("setdefault", listId);
    },
    removeList(listId, listName) {
      this.$emit("remove", { listId, listName });
    },
    setEdit(list) {
      Vue.set(list, "isEdit", true);
      Vue.set(list, "tempName", list.name);
      //   list.isEdit = true;
      //   list.tempName = list.name;
      //   this.$emit("remove", listId);
    },
    undoEdit(list) {
      list.isEdit = false;
      list.tempName = null;
    },
    confirmEdit(list) {
      this.$emit("rename", list);
    },
    async addAllToCart(list) {
      await this.addProductsFromWishlist(list.id);
    },
    setOpenList(listId) {
      console.log(listId);
      this.setOpenedListId(listId);
    }
  },
  created() {
    console.log(this);
  },
  watch: {
    listsData(val, oldVal) {
      if (val !== oldVal) {
        this.$nextTick(() => {
          if (this.$refs.listsContainer) {
            this.$refs.listsContainer.scrollTop =
              this.$refs.listsContainer?.scrollHeight + 60;
          }
        });
      }
    }
  }
};
</script>
