<template>
  <div class="generic-list scroll-container">
    <!-- <v-container class="fill-height"> -->

    <v-row no-gutters class="list-page">
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <!-- menu laterale sx: elenco liste -->
        <MenuNavigator class="menu-navigator lists-menu" />

        <ListsMenu
          v-if="!$vuetify.breakpoint.sm"
          :listsData="lists"
          @setdefault="setDefaultList"
          @remove="onRemoveList"
          @rename="onRenameList"
          @created="onListCreated"
        />

        <v-card
          light
          elevation="0"
          v-if="lists && lists.length === 0"
          class="no-lists pa-2 text-center"
          style="width:100%;"
        >
          <v-card-title class="text-body-1 text-center font-weight-bold"
            >Nessuna lista trovata</v-card-title
          >
        </v-card>
      </v-col>
      <v-col class="relative" cols="12" md="9">
        <!-- pagina principale: dettaglio lista -->
        <!-- <ProfileTitle :category="category" /> -->
        <v-skeleton-loader
          v-if="loading"
          :type="$vuetify.breakpoint.xs ? 'card' : 'list-item-three-line'"
        />
        <div v-else>
          <ListPreferred v-if="listId == 'preferred'" />
          <ListDetail v-else-if="listId" :listId="listId" :key="componentKey" />
          <div
            v-else
            class="text-h5 text-sm-h4 text-center text-sm-left primary--text font-weight-bold pa-3"
          >
            Seleziona una lista da Le mie Liste o crea una nuova lista
          </div>
        </div>
        <v-speed-dial
          ref="speedDial"
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="fab"
          fixed
          direction="bottom"
          transition="slide-y-transition"
          class="lists-filter"
          :class="{ closed: !fab }"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" small color="secondary" fab>
              <v-icon v-if="fab">
                $close
              </v-icon>
              <div v-else>
                <v-icon small class="mr-1">
                  $list
                </v-icon>
                <span>Le mie liste</span>
              </div>
            </v-btn>
          </template>
          <ListsMenu
            v-ripple="false"
            :listsData="lists"
            @setdefault="setDefaultList"
            @remove="onRemoveList"
            @rename="onRenameList"
            @created="onListCreated"
            @loadlist="fab = false"
          />
        </v-speed-dial>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.generic-list {
  :deep(.v-skeleton-loader__list-item-three-line),
  :deep(.v-skeleton-loader__card) {
    background: transparent !important;
  }
  .no-lists {
    background: transparent;
    .v-card__title {
      word-break: break-word;
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  :deep(.lists-menu) {
    background-color: $white !important;
  }
}
</style>

<script>
// @ is an alias to /src
// import ProfileTitle from "@/components/profile/ProfileTitle";
import MenuNavigator from "@/components/profile/MenuNavigator.vue";
import ListsMenu from "@/components/lists/ListsMenu.vue";
import ListDetail from "@/components/lists/ListDetail.vue";
import ListPreferred from "@/components/lists/ListPreferred.vue";

// import debounce from "lodash/debounce";

import list from "~/mixins/list";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "Lists",
  mixins: [list, categoryMixins, deliveryReactive],
  data() {
    return {
      page: {},
      listId: null,
      fab: false,
      prof: false,
      loading: true,
      componentKey: 0
    };
  },
  components: {
    MenuNavigator,
    ListsMenu,
    ListDetail,
    ListPreferred
  },
  methods: {
    checkListId() {},
    onListCreated() {
      this.reload();
    },
    async onRemoveList(listId, listName) {
      let result = await this.removeList(listId, listName);
      if (
        result &&
        result.length &&
        this.$route.params?.listId === listId.listId
      ) {
        let defaultList = result.find(r => {
          return r.isDefault;
        });
        if (defaultList) {
          this.$router.push({
            name: "List",
            params: { listId: defaultList.id }
          });
        }
      }
    },
    async onRenameList(list) {
      let result = await this.renameList(list);

      if (result) {
        this.reload();
        this.$nextTick(() => {
          this.componentKey++;
        });
      }
    }
  },
  created() {
    this.reload();
  },
  watch: {
    lists() {
      if (this.$route && this.$route.params && !this.$route.params.listId) {
        for (var i = 0; i < this.lists.length; i++) {
          if (this.lists[i].isDefault) {
            this.listId = this.lists[i].id;
            this.$router.push({
              name: "List",
              params: { listId: this.listId }
            });
          }
        }
      } else {
        this.listId = this.$route.params.listId;
      }
      this.loading = false;
    },
    $route(to) {
      this.listId = to.params.listId;
    },
    fab(val, oldVal) {
      console.log("fab: " + val);
      console.log(oldVal);
    }
  }
};
</script>
